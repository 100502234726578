<template>
    <span class="field-validation-result" v-if="show">
        <span v-html="message"></span>
    </span>
</template>





<script>
export default {

	props: {
		
		/**
		 * The result message to display.
		 */
		message: {
			type: String,
			required: true,
		},
	},



	watch: {
		message: function(to, from)
		{
			this.messageValue = to;
			this.calculateShow();
		},
	},



    mounted()
    {
        //console.log("field-validation-result.mounted()");
        this.init();
    },



    updated()
    {
        //console.log("field-validation-result.updated()");
        this.init();
    },



	data: function()
	{
		return {
			messageValue: "",
			show: false
		};
	},



    methods: {
		init()
		{
			this.messageValue = this.message;

			this.calculateShow();
		},


        calculateShow()
        {
			//console.log("calculateShow(): messageValue=", this.messageValue);
            if (this.messageValue != "")
            {
                this.show = true;
            }
        }
    }
}
</script>





<style lang="scss" scoped>
    .field-validation-result
    {
        color:red;
        // display: inline-block;	//TODO: Changed to block 11 Feb 2023. This needs a portal wide retest.
		display: block;

		//TODO: Added 11 Feb 2023. This needs a portal wide retest.
		font-size: 75%;
    }
</style>