var buttonPropsMixin = {

	emits: ["click"],


	props: {
		
		
		/**
		 * Values include: "standard"; "primary" (default); "secondary"/"info"; "teriary" (muted), "danger", "warning".
		 */
		type: {
			type: String,
			required: false,
			default: "primary"
		},

		/**
		 * Does this button have a drop-down menu?
		 */
		isDropDown: {
			type: Boolean,
			required: false,
			default: false
		},


		/**
		 * For Element buttons. Same the the native button type (button / submit / reset).
		 */
		nativeType: {
			type: String,
			default: "button"
		},




		initialValue: {
			type: String
		},

		updateValue: {
			type: String
		},

	},





	data()
	{
		return {
			btnClass: "",

			displayType: "default",

			isPlain: false
		};
	},







	computed: {

		getSize() {
			if (this.size.toLowerCase() == "standard".toLowerCase()) return "default";
			
			if (this.size.toLowerCase() == "small".toLowerCase()) return "small";
			
			//NOTE: Had to add this when upgrading to Vue 3 and switching from element-ui to element-plus.
			//if (this.size.toLowerCase() == "mini".toLowerCase()) return "mini";
			if (this.size.toLowerCase() == "mini".toLowerCase()) return "small";

			if (this.size.toLowerCase() == "large".toLowerCase()) return "large";

			return "";
		},


		getClass()
		{
			const fallthrough = this.$attrs.class;

			//console.log("button-props-mixin.getClass(): fallthrough=", fallthrough);

			return fallthrough;
		},

	},




	methods: {
		onClick()
		{
			//console.log("button-props-mixin.click(): id=", this.id);

			//[@jason, 13 Feb 2023]
			//NOTE: el-button has no `@click="onClick"` event handler.
			//It just emits a click - that's it.
			//If we also emit from this onClick it's a double emit for the honsting components capturing the @click.
			//So, until now I've incorrectly had on `@click="onClick"` on <el-button> components in all custom button components.
			//I'm leaving the HTML, but commenting out the following emit.

			//TODO: DO not uncomment this with <el-button> components for fear of a double `click` emit.
			//TODO: Update - so, that seems to have broken the buttons. Maybe I just needed to incldue the `emits` in components for Vue 3.
			const _this = this;
			this.$emit('click', {
				id: _this.id
			});
		},


		onClickNative()
		{
			console.log("button-props-mixin.onClickNative(): id=", this.id);

			//[@jason, 13 Feb 2023]
			//NOTE: el-button has no `@click="onClick"` event handler.
			//It just emits a click - that's it.
			//If we also emit from this onClick it's a double emit for the honsting components capturing the @click.
			//So, until now I've incorrectly had on `@click="onClick"` on <el-button> components in all custom button components.
			//I'm leaving the HTML, but commenting out the following emit.

			//TODO: DO not uncomment this with <el-button> components for fear of a double `click` emit.
			const _this = this;
			this.$emit('click', {
				id: _this.id
			});
		}
	}

};

export default buttonPropsMixin;