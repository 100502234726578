<!-- eslint-disable vue/multi-word-component-names -->
<template>
	<div class='c-tab tab-content' v-show='id == selectedId'>
		<slot></slot>
	</div>
</template>






<script>
// Credit: https://www.youtube.com/watch?v=ROGeJxI2L58
// Credit: https://github.com/mattmaribojoc/learn-vue-tab
import { inject} from 'vue';

export default {
	name: "CTab",


	
	props: {
		title: {
			type: String,
			default: 'Tab'
		},

		/**
		 * The `id` of this tab. Required.  
		 * Should be unique among all elements currently rendered on the page.
		 */
		id: {
			type: String,
			required: true
		},
	},
	// data() {
	// 	return {
	// 		isActive: true
	// 	}
	// },


	setup()
	{
		//const selectedTitle = inject("selectedTitle");
		const selectedId = inject("selectedId");


		return {
			//selectedTitle,
			selectedId,
		}
	}

}
</script>






<style scoped lang="scss">
	.tab-content
	{
		/*
		// min-height: 400px;
		// padding: 20px;
		// border-radius: 5px;
		// box-shadow: 0 3px 5px 5px rgba(0, 0, 0, 0.05);
		*/
	}
</style>