const routes = [

	// Notes: Set `meta.isPublic: true` for any pages not requiring authentication. This is used by `app.checkCurrentAuthTokenStillValid()` so validation is not checked on public pages.


	//-- Publicly accessible pages
	{ 
		path: '/', 
		name: "root", 
		component: () => import("./public/index.vue"),
		meta: { layout: "public", isPublic: true, pageTitle: "", kbFile: "public", kbTitle: "Public (Home, Login, Forgot Password)" } 
	},
	{ 
		path: '/login', 
		name: "login", 
		component: () => import("./public/login.vue"),
		meta: { layout: "public", isPublic: true, pageTitle: "Login", kbFile: "public", kbTitle: "Public (Home, Login, Forgot Password)" } 
	},
	{ 
		path: '/logout', 
		name: "logout", 
		component: () => import("./public/logout.vue"),
		meta: { layout: "public", isPublic: true, pageTitle: "Logout", kbFile: "public", kbTitle: "Public (Home, Login, Forgot Password)" } 
	},
	{ 
		path: '/forgot-password', 
		name: "forgot-password", 
		component: () => import("./public/forgot-password.vue"),
		meta: { layout: "public", isPublic: true, pageTitle: "Forgot Password", kbFile: "public", kbTitle: "Public (Home, Login, Forgot Password)" } 
	},
	{ 
		path: '/reset-password/:token', 
		name: "reset-password", 
		component: () => import("./public/reset-password.vue"),
		meta: { layout: "public", isPublic: true, pageTitle: "Reset Password", kbFile: "public", kbTitle: "Public (Home, Login, Forgot Password)" } 
	},

	{ 
		path: '/not-found', 
		name: "not-found", 
		component: () => import("./public/not-found.vue"),
		meta: { layout: "public", isPublic: true, pageTitle: "Not Found" } 
	},

	{ 
		path: '/delete-account', 
		name: "delete-account", 
		component: () => import("./public/delete-account.vue"),
		meta: { layout: "public", isPublic: true, pageTitle: "Request Account Deletion" } 
	},



	//TODO: 9 July 2023 - These endpoints are working, but disabling for now while in private beta.
	//
	// //-- Create account
	// { 	// Primary alias for /create-account/start
	// 	path: '/create-account', 
	// 	name: "create-account-start-root", 
	// 	component: () => import("./public/create-account/start.vue"),
	// 	meta: { layout: "primary-domain-consistent", isPublic: true, pageTitle: "Create Account", kbFile: "public", kbTitle: "Public (Home, Login, Forgot Password, Signup)" } 
	// },
	// {
	// 	path: '/create-account/start', 
	// 	name: "create-account-start", 
	// 	component: () => import("./public/create-account/start.vue"),
	// 	meta: { layout: "primary-domain-consistent", isPublic: true, pageTitle: "Create Account", kbFile: "public", kbTitle: "Public (Home, Login, Forgot Password, Signup)" } 
	// },
	// { 
	// 	path: '/create-account/verify', 
	// 	name: "create-account-verify", 
	// 	component: () => import("./public/create-account/verify.vue"),
	// 	meta: { layout: "primary-domain-consistent", isPublic: true, pageTitle: "Create Account - Verify Your Email", kbFile: "public", kbTitle: "Public (Home, Login, Forgot Password, Signup)" } 
	// },
	// { 
	// 	path: '/create-account/complete', 
	// 	name: "create-account-complete", 
	// 	component: () => import("./public/create-account/complete.vue"),
	// 	meta: { layout: "primary-domain-consistent", isPublic: true, pageTitle: "Create Account - Complete!", kbFile: "public", kbTitle: "Public (Home, Login, Forgot Password, Signup)" } 
	// },






















	//---- Logged in "Home" page (start/landing/dashboard)
	{
		path: '/home', 
		name: "home", 
		component: () => import("./home/index.vue"),
		meta: { layout: "primary", layoutType: "default", layoutContainer: "none", isPublic: true, pageTitle: "Home", kbFile: "home", kbTitle: "" } 
	},
	{ 
		path: '/home/LotusOnly', 
		name: "home-lotus-only-view", 
		component: () => import("./home/index.vue"),
		meta: { layout: "primary", layoutType: "default", layoutContainer: "none", isPublic: true, pageTitle: "Lotus Only > Home", kbFile: "home", kbTitle: "" } 
	},



	//TODO: Not used at the moment. Initial idea stage.
	{ 
		path: '/get-started', 
		name: "get-started-root", 
		component: () => import("./home/get-started.vue"),
		meta: { layout: "primary", isPublic: true, pageTitle: "Get Started with Lotus", kbFile: "get-started", kbTitle: "Get Started" } 
	},


































	//---- "Timesheet" pages

	{ 
		path: '/home/timesheets', 
		name: "home-timesheets-view", 
		component: () => import("./home/index.vue"),
		meta: { layout: "primary", layoutType: "default", layoutContainer: "none", isPublic: true, pageTitle: "Timesheets > Home", kbFile: "home", kbTitle: "" } 
	},

	//-- "Practitioner" pages
	{ 
		path: '/module/timesheets/create', 
		name: "timesheet-create", 
		component: () => import("./module.timesheets/create-timesheet.vue"),
		meta: { layout: "primary", pageTitle: "Create Timesheet", kbFile: "timesheet", kbTitle: "Timesheet" } 
	},

	//TODO: 20 June 2021. The original "read-only" timesheet page is now obsolete. Redirect to edit page (because it has a different layout).
	// { 
	// 	path: '/timesheet', 
	// 	name: "no-selected-timesheet", 
	// 	component: () => import("./module.timesheets/timesheet-page"),
	// 	meta: { layout: "practitioner", pageTitle: "Timesheets > View", satus: "disabled", redirectCode: "home", kbFile: "timesheet", kbTitle: "Timesheet" } 
	// },
	// { 
	// 	path: '/module/timesheets/id/:id', 
	// 	name: "view-timesheet", 
	// 	// component: () => import("./module.timesheets/timesheet-page"),
	// 	component: () => import("./module.timesheets/edit-timesheet-page"),
	// 	meta: { layout: "practitioner", pageTitle: "Timesheets > View", satus: "disabled", redirectCode: "edit-timesheet", kbFile: "timesheet", kbTitle: "Timesheet" } 
	// },

	// //TODO: The following "*/ConfirmViaEmail/*" routes are not yet active. See daily timesheet email in the API for generated links.
	// { 
	// 	path: '/module/timesheets/ConfirmViaEmail/:id/:authToken', 		//:id == timesheetId. Left as ID so standard "timesheet" route also uses it.
	// 	name: "timesheet-confirm-via-email-auth", 
	// 	component: () => import("./module.timesheets/edit-timesheet-page"),
	// 	meta: { layout: "practitioner", pageTitle: "Timesheets > Confirm", satus: "disabled", redirectCode: "home", kbFile: "timesheet", kbTitle: "Timesheet" } 
	// },
	// { 
	// 	path: '/module/timesheets/ConfirmViaEmail/:id', 		//:id == timesheetId. Left as ID so standard "timesheet" route also uses it.
	// 	name: "timesheet-confirm-via-email", 
	// 	component: () => import("./module.timesheets/edit-timesheet-page"),
	// 	meta: { layout: "practitioner", pageTitle: "Timesheets > Confirm", satus: "disabled", redirectCode: "home", kbFile: "timesheet", kbTitle: "Timesheet" } 
	// },
	// { 
	// 	path: '/module/timesheets/ChangeViaEmail/:id/:authToken', 		//:id == timesheetId. Left as ID so standard "timesheet" route also uses it.
	// 	name: "timesheet-change-via-email-auth", 
	// 	component: () => import("./module.timesheets/edit-timesheet-page"),
	// 	meta: { layout: "practitioner", pageTitle: "Timesheets > Change", satus: "disabled", redirectCode: "home", kbFile: "timesheet", kbTitle: "Timesheet" } 
	// },
	// { 
	// 	path: '/module/timesheets/ChangeViaEmail/:id', 		//:id == timesheetId. Left as ID so standard "timesheet" route also uses it.
	// 	name: "timesheet-change-via-email", 
	// 	component: () => import("./module.timesheets/edit-timesheet-page"),
	// 	meta: { layout: "practitioner", pageTitle: "Timesheets > Change", satus: "disabled", redirectCode: "home", kbFile: "timesheet", kbTitle: "Timesheet" } 
	// },
	
	{ 
		path: '/timesheet', 
		name: "no-selected-timesheet", 
		component: () => import("./home/redirect.vue"),
		meta: { layout: "primary", pageTitle: "Timesheets > View", satus: "disabled", redirectCode: "home", kbFile: "timesheet", kbTitle: "Timesheet" } 
	},
	{ 
		path: '/module/timesheets/id/:id', 
		name: "view-timesheet", 
		component: () => import("./home/redirect.vue"),
		meta: { layout: "primary", pageTitle: "Timesheets > View", satus: "disabled", redirectCode: "edit-timesheet", kbFile: "timesheet", kbTitle: "Timesheet" } 
	},

	//TODO: The following "*/ConfirmViaEmail/*" routes are not yet active. See daily timesheet email in the API for generated links.
	{ 
		path: '/module/timesheets/ConfirmViaEmail/:id/:authToken', 		//:id == timesheetId. Left as ID so standard "timesheet" route also uses it.
		name: "timesheet-confirm-via-email-auth", 
		component: () => import("./home/redirect.vue"),
		meta: { layout: "primary", pageTitle: "Timesheets > Confirm", satus: "disabled", redirectCode: "home", kbFile: "timesheet", kbTitle: "Timesheet" } 
	},
	{ 
		path: '/module/timesheets/ConfirmViaEmail/:id', 		//:id == timesheetId. Left as ID so standard "timesheet" route also uses it.
		name: "timesheet-confirm-via-email", 
		component: () => import("./home/redirect.vue"),
		meta: { layout: "primary", pageTitle: "Timesheets > Confirm", satus: "disabled", redirectCode: "home", kbFile: "timesheet", kbTitle: "Timesheet" } 
	},
	{ 
		path: '/module/timesheets/ChangeViaEmail/:id/:authToken', 		//:id == timesheetId. Left as ID so standard "timesheet" route also uses it.
		name: "timesheet-change-via-email-auth", 
		component: () => import("./home/redirect.vue"),
		meta: { layout: "primary", pageTitle: "Timesheets > Change", satus: "disabled", redirectCode: "home", kbFile: "timesheet", kbTitle: "Timesheet" } 
	},
	{ 
		path: '/module/timesheets/ChangeViaEmail/:id', 		//:id == timesheetId. Left as ID so standard "timesheet" route also uses it.
		name: "timesheet-change-via-email", 
		component: () => import("./home/redirect.vue"),
		meta: { layout: "primary", pageTitle: "Timesheets > Change", satus: "disabled", redirectCode: "home", kbFile: "timesheet", kbTitle: "Timesheet" } 
	},



	{
		path: '/module/timesheets/id/:id/edit', 
		name: "edit-timesheet", 
		component: () => import("./module.timesheets/edit-timesheet.vue"),
		meta: { layout: "primary", layoutContainer: "none", pageTitle: "Timesheet", kbFile: "timesheet", kbTitle: "Timesheet" } 
	},

	//NOTE: Same as for the path above. This is the original URL for the page prior to the restructure of the platform into modiles.
	//		It's still used by daily timehsheet emails generated overnight for users, so we need to keep it (and update that link)
	{
		path: '/timesheets/id/:id/edit', 
		name: "edit-timesheet-old-daily-email-link", 
		component: () => import("./module.timesheets/edit-timesheet.vue"),
		meta: { layout: "primary", layoutContainer: "none", pageTitle: "Timesheet", kbFile: "timesheet", kbTitle: "Timesheet" } 
	},



	//-- Timesheet "Manager" pages (Team Manager and Project Manager)
	{ 
		path: '/module/timesheets/manager/timesheets-report', 
		name: "timesheets-manager-report",
		component: () => import("./module.timesheets/manager/timesheets-report.vue"),
		meta: { layout: "primary", pageTitle: "Timesheets > Manager > Timesheet Report" } 
	},
	{ 
		path: '/module/timesheets/manager/outstanding-timesheets', 
		name: "timesheets-manager-outstanding",
		component: () => import("./module.timesheets/manager/outstanding-timesheets.vue"),
		meta: { layout: "primary", pageTitle: "Timesheets > Manager > Outstanding Timesheets" }
	},
	{ 
		path: '/module/timesheets/manager/manager-users-list', 
		name: "timesheets-manager-users-list",
		component: () => import("./module.timesheets/manager/users-list.vue"),
		meta: { layout: "primary", pageTitle: "Timesheets > Manager > Users" }
	},


	// Record time in today's timesheet
	{ 
		path: '/module/timesheets/record', 
		name: "record-timesheet", 
		component: () => import("./module.timesheets/time-recorder/index.vue"),
		meta: { layout: "primary", pageTitle: "Timesheet > Record Time", kbFile: "timesheet-record-time", kbTitle: "Timesheet > Record Time" } 
	},





























	//---- Timesheet module: "Buckets Report" pages
	{ 
		//path: '/buckets', 
		path: '/module/timesheets/buckets-report', 
		name: "timesheet-buckets-report-entry",
		component: () => import("./module.timesheets/buckets/index.vue"),
		meta: { layout: "primary", pageTitle: "Buckets Report", kbFile: "buckets", kbTitle: "Buckets" } 
	},
	{ 	// For system admin, and later when a user can operate across accounts
		//path: '/buckets/:accountId', 
		path: '/module/timesheets/buckets-report/:accountId', 
		name: "timesheet-buckets-report-account",
		component: () => import("./module.timesheets/buckets/index.vue"),
		meta: { layout: "primary", pageTitle: "Buckets Report", kbFile: "buckets", kbTitle: "Buckets" } 
	},

	{ 
		//path: '/buckets/bucket/:bucketId/summary', 
		path: '/module/timesheets/buckets-report/bucket/:bucketId/summary', 
		name: "timesheet-buckets-report-bucket-summary",
		component: () => import("./module.timesheets/buckets/bucket-summary.vue"),
		meta: { layout: "primary", pageTitle: "Buckets Report > Bucket Summary", kbFile: "buckets", kbTitle: "Buckets" } 
	},
	{ 
		//path: '/buckets/bucket/:bucketId/detail', 
		path: '/module/timesheets/buckets-report/bucket/:bucketId/detail', 
		name: "timesheet-buckets-report-bucket-detail",
		component: () => import("./module.timesheets/buckets/bucket-detail.vue"),
		meta: { layout: "primary", layoutType: "manage", pageTitle: "Buckets Report > Bucket Detail", kbFile: "buckets", kbTitle: "Buckets" } 
	},

	// { 
	// 	path: '/manage/:accountId/clients', 
	// 	name: "manage-clients", 
	// 	component: () => import("./manage/clients.vue"),
	// 	meta: { layout: "primary", layoutType: "manage", layoutContainer: "none", pageTitle: "Manage > Clients", kbFile: "manage", kbTitle: "Manage" }
	// },

	{ 
		//path: '/buckets/bucket/:bucketId/breakdown', 
		path: '/module/timesheets/buckets-report/bucket/:bucketId/breakdown', 
		name: "timesheet-buckets-report-bucket-breakdown",
		component: () => import("./module.timesheets/buckets/bucket-breakdown.vue"),
		meta: { layout: "primary", pageTitle: "Buckets Report > Bucket Breakdown", kbFile: "buckets", kbTitle: "Buckets" } 
	},

	





















	//---- My Profile
	{ 
		path: '/my-profile', 
		name: "my-profile",
		component: () => import("./my-profile/index.vue"),
		meta: { layout: "primary", pageTitle: "My Profile", kbFile: "my-profile", kbTitle: "My Profile" } 
	},
	{ 
		path: '/my-location', 
		name: "my-location",
		component: () => import("./my-profile/my-location.vue"),
		meta: { layout: "primary", pageTitle: "My Location", kbFile: "my-location", kbTitle: "My Location" } 
	},




















	//---- Support
	{ 
		path: '/support', 
		name: "support",
		component: () => import("./support/index.vue"),
		meta: { layout: "primary", pageTitle: "Request Support", kbFile: "support", kbTitle: "Support" } 
	},

	


















	//---- "Account Admin" pages
	{ 
		path: '/account-admin',
		name: "account-admin-root", 
		component: () => import("./account-admin/index.vue"),
		// meta: { layout: "account-admin", pageTitle: "Account Admin", kbFile: "account-admin", kbTitle: "Account Admin" }
		meta: { layout: "primary", layoutType: "account-admin", layoutContainer: "none", pageTitle: "Account Admin", kbFile: "account-admin", kbTitle: "Account Admin" }
	},

	// Create a new account as a Lotus person. Based on the original `path: '/system-admin/accounts/new',`
	{ 
		path: '/system-admin/accounts/new', 	
		name: "system-admin-accounts-new",
		component: () => import("./system-admin/account.vue"),
		meta: { layout: "primary", section: "system-admin", pageTitle: "Lotus Admin > Account" }
	},

	{ 
		path: '/account-admin/:accountId',
		name: "account-admin-home", 
		component: () => import("./account-admin/index.vue"),
		//meta: { layout: "account-admin", pageTitle: "Account Admin", kbFile: "account-admin", kbTitle: "Account Admin" }
		meta: { layout: "primary", layoutType: "account-admin", layoutContainer: "none", pageTitle: "Account Admin > Account details", kbFile: "account-admin", kbTitle: "Account Admin" }
	},

	{ 
		path: '/account-admin/:accountId/settings', 
		name: "account-admin-settings", 
		component: () => import("./account-admin/settings.vue"),
		meta: { layout: "primary", layoutType: "account-admin", layoutContainer: "none", pageTitle: "Account Admin > Settings", kbFile: "account-admin", kbTitle: "Account Admin" }
	},

	{ 
		path: '/account-admin/:accountId/users', 
		name: "account-admin-users", 
		component: () => import("./account-admin/users.vue"),
		meta: { layout: "primary", layoutType: "account-admin", layoutContainer: "none", pageTitle: "Account Admin > People", kbFile: "account-admin", kbTitle: "Account Admin" }
	},
	{ 
		path: '/account-admin/:accountId/user/:id', 
		name: "account-admin-user-edit", 
		component: () => import("./account-admin/user.vue"),
		meta: { layout: "primary", layoutType: "account-admin", layoutContainer: "none", pageTitle: "Account Admin > Update Person", kbFile: "account-admin", kbTitle: "Account Admin" }
	},
	{ 
		path: '/account-admin/:accountId/user/:id/access', 
		name: "account-admin-user-access", 
		component: () => import("./account-admin/user-access.vue"),
		meta: { layout: "primary", layoutType: "account-admin", layoutContainer: "none", pageTitle: "Account Admin > Manage Person Access", kbFile: "account-admin", kbTitle: "Account Admin" }
	},
	{ 
		path: '/account-admin/:accountId/users/new',  
		name: "account-admin-user-new", 
		component: () => import("./account-admin/user.vue"),
		meta: { layout: "primary", layoutType: "account-admin", pageTitle: "Account Admin > New Person", kbFile: "account-admin", kbTitle: "Account Admin" }
	},

	



















	
	//---- "Manage" pages
	{ 
		path: '/manage',
		name: "manage-home", 
		component: () => import("./manage/index.vue"),
		meta: { layout: "primary", layoutType: "manage", layoutContainer: "none", pageTitle: "Manage", kbFile: "manage", kbTitle: "Manage" }
	},

	{ 
		path: '/manage/jobs', 
		name: "manage-jobs", 
		component: () => import("./manage/jobs.vue"),
		meta: { layout: "primary", layoutType: "manage", layoutContainer: "none", pageTitle: "Manage > Jobs", kbFile: "manage", kbTitle: "Manage" }
	},
	{ 
		path: '/manage/job/:jobId/edit', 
		name: "manage-jobs-edit", 
		component: () => import("./manage/job-edit.vue"),
		meta: { layout: "primary", layoutType: "manage", layoutContainer: "none", pageTitle: "Manage > Job > Edit", kbFile: "manage", kbTitle: "Manage" }
	},
	{ 
		path: '/manage/jobs/new', 
		name: "manage-jobs-new", 
		component: () => import("./manage/job-edit.vue"),
		meta: { layout: "primary", layoutType: "manage", layoutContainer: "none", pageTitle: "Manage > Jobs > New", kbFile: "manage", kbTitle: "Manage" }
	},


	{ 
		path: '/manage/addresses', 
		name: "manage-addresses", 
		component: () => import("./manage/addresses.vue"),
		meta: { layout: "primary", layoutType: "manage", layoutContainer: "none", pageTitle: "Manage > Addresses", kbFile: "manage", kbTitle: "Manage" }
	},
	{ 
		path: '/manage/address/:addressId/edit', 
		name: "manage-address-edit", 
		component: () => import("./manage/address-edit.vue"),
		meta: { layout: "primary", layoutType: "manage", layoutContainer: "none", pageTitle: "Manage > Address > Edit", kbFile: "manage", kbTitle: "Manage" }
	},
	{ 
		path: '/manage/addresses/new', 
		name: "manage-addresses-new", 
		component: () => import("./manage/address-edit.vue"),
		meta: { layout: "primary", layoutType: "manage", layoutContainer: "none", pageTitle: "Manage > Addresses > New", kbFile: "manage", kbTitle: "Manage" }
	},


	{ 
		path: '/manage/organisations', 
		name: "manage-organisations", 
		component: () => import("./manage/organisations.vue"),
		meta: { layout: "primary", layoutType: "manage", layoutContainer: "none", pageTitle: "Manage > Organisations", kbFile: "manage", kbTitle: "Manage" }
	},
	{ 
		path: '/manage/organisation/:organisationId/edit', 
		name: "manage-organisation-edit", 
		component: () => import("./manage/organisation-edit.vue"),
		meta: { layout: "primary", layoutType: "manage", layoutContainer: "none", pageTitle: "Manage > Organisation > Edit", kbFile: "manage", kbTitle: "Manage" }
	},
	{ 
		path: '/manage/organisations/new', 
		name: "manage-organisations-new", 
		component: () => import("./manage/organisation-edit.vue"),
		meta: { layout: "primary", layoutType: "manage", layoutContainer: "none", pageTitle: "Manage > Organisations > New", kbFile: "manage", kbTitle: "Manage" }
	},


	{ 
		path: '/manage/people', 
		name: "manage-people", 
		component: () => import("./manage/people.vue"),
		meta: { layout: "primary", layoutType: "manage", layoutContainer: "none", pageTitle: "Manage > People", kbFile: "manage", kbTitle: "Manage" }
	},
	{ 
		path: '/manage/people/:personId/edit', 
		name: "manage-person-edit", 
		component: () => import("./manage/person-edit.vue"),
		meta: { layout: "primary", layoutType: "manage", layoutContainer: "none", pageTitle: "Manage > Person > Edit Person", kbFile: "manage", kbTitle: "Manage" }
	},
	{ 
		path: '/manage/people/new', 
		name: "manage-people-new", 
		component: () => import("./manage/person-edit.vue"),
		meta: { layout: "primary", layoutType: "manage", layoutContainer: "none", pageTitle: "Manage > People > New Person", kbFile: "manage", kbTitle: "Manage" }
	},



	


	{ 
		path: '/manage/:accountId/clients', 
		name: "manage-clients", 
		component: () => import("./manage/clients.vue"),
		meta: { layout: "primary", layoutType: "manage", layoutContainer: "none", pageTitle: "Manage > Clients", kbFile: "manage", kbTitle: "Manage" }
	},
	{ 
		path: '/manage/:accountId/client/:clientId/edit', 
		name: "manage-clients-edit", 
		component: () => import("./manage/client-edit.vue"),
		meta: { layout: "primary", layoutType: "manage", layoutContainer: "none", pageTitle: "Manage > Client", kbFile: "manage", kbTitle: "Manage" }
	},
	{ 
		path: '/manage/:accountId/clients/new', 
		name: "manage-clients-new", 
		component: () => import("./manage/client-edit.vue"),
		meta: { layout: "primary", layoutType: "manage", layoutContainer: "none", pageTitle: "Manage > New Client", kbFile: "manage", kbTitle: "Manage" }
	},

	{ 
		path: '/manage/:accountId/buckets',
		name: "manage-buckets", 
		component: () => import("./manage/buckets.vue"),
		meta: { layout: "primary", layoutType: "manage", layoutContainer: "none", pageTitle: "Manage > Buckets", kbFile: "manage", kbTitle: "Manage" }
	},
	{ 
		path: '/manage/:accountId/bucket/:bucketId/edit', 
		name: "manage-bucket-edit", 
		component: () => import("./manage/bucket-edit.vue"),
		meta: { layout: "primary", layoutType: "manage", layoutContainer: "none", pageTitle: "Manage > Bucket", kbFile: "manage", kbTitle: "Manage" }
	},
	{ 
		path: '/manage/:accountId/buckets/new', 
		name: "manage-bucket-new", 
		component: () => import("./manage/bucket-edit.vue"),
		meta: { layout: "primary", layoutType: "manage", layoutContainer: "none", pageTitle: "Manage > New Bucket", kbFile: "manage", kbTitle: "Manage" }
	},
	{ 
		path: '/manage/:accountId/client/:clientId/buckets/new', 
		name: "manage-bucket-new-for-client", 
		component: () => import("./manage/bucket-edit.vue"),
		meta: { layout: "primary", layoutType: "manage", layoutContainer: "none", pageTitle: "Manage > New Bucket", kbFile: "manage", kbTitle: "Manage" }
	},


	{ 
		path: '/manage/:accountId/clients-buckets-tasks',
		name: "manage-clients-buckets-tasks", 
		component: () => import("./manage/clients-buckets-tasks.vue"),
		meta: { layout: "primary", layoutType: "manage", layoutContainer: "none", pageTitle: "Manage > Clients, Buckets & Tasks", kbFile: "manage", kbTitle: "Manage" }
	},




	{ 
		path: '/manage/:accountId/bucket/:bucketId/tasks', 
		name: "manage-bucket-tasks", 
		component: () => import("./manage/bucket-tasks.vue"),
		meta: { layout: "primary", layoutType: "manage", layoutContainer: "none", pageTitle: "Manage > Bucket > Tasks", kbFile: "manage", kbTitle: "Manage" }
	},
	{ 
		path: '/manage/:accountId/bucket/:bucketId/tasks/:taskId/edit', 
		name: "manage-bucket-task-edit", 
		component: () => import("./manage/bucket-task-edit.vue"),
		meta: { layout: "primary", layoutType: "manage", layoutContainer: "none", pageTitle: "Manage > Bucket > Edit Task", kbFile: "manage", kbTitle: "Manage" }
	},
	{ 
		path: '/manage/:accountId/bucket/:bucketId/tasks/new', 
		name: "manage-bucket-task-new", 
		component: () => import("./manage/bucket-task-edit.vue"),
		meta: { layout: "primary", layoutType: "manage", layoutContainer: "none", pageTitle: "Manage > Bucket > New Task", kbFile: "manage", kbTitle: "Manage" }
	},



	{ 
		path: '/manage/:accountId/bucket/keywords', 
		name: "manage-keywords-all", 
		component: () => import("./manage/bucket-keywords.vue"),
		meta: { layout: "primary", layoutType: "manage", layoutContainer: "none", pageTitle: "Manage > Keywords", kbFile: "manage", kbTitle: "Manage" }
	},
	{ 
		path: '/manage/:accountId/bucket/:bucketId/keywords', 
		name: "manage-bucket-keywords", 
		component: () => import("./manage/bucket-keywords.vue"),
		meta: { layout: "primary", layoutType: "manage", layoutContainer: "none", pageTitle: "Manage > Bucket > Keywords", kbFile: "manage", kbTitle: "Manage" }
	},
	{ 
		path: '/manage/:accountId/pending-keywords', 
		name: "manage-pending-keywords", 
		component: () => import("./manage/pending-keywords.vue"),
		meta: { layout: "primary", layoutType: "manage", layoutContainer: "none", pageTitle: "Manage > Pending Keywords", kbFile: "manage", kbTitle: "Manage" }
	},
	{ 
		path: '/manage/:accountId/bucket/:bucketId/rates', 
		name: "manage-bucket-rates", 
		component: () => import("./manage/rates.vue"),
		meta: { layout: "primary", layoutType: "manage", layoutContainer: "none", pageTitle: "Manage > Buckets", kbFile: "manage", kbTitle: "Manage" }
	},







	//---- "Keywords" pages
	{ 
		path: '/module/timesheets/keywords/new-detected', 
		name: "timesheets-new-detected-keywords", 
		component: () => import("./module.timesheets/keywords/new-detected.vue"),
		meta: { layout: "primary", section: "keywords", pageTitle: "Keywords > New Detected" }
	},






























	// ---- Quotes module

	// Quoting dashboard.
	{
		path: '/module/quotes',
		name: 'quotes-root',
		component: () => import('./module.quotes/quotes-dashboard.vue'),
		meta: {
			layout: "primary",
			section: "quotes",
			pageTitle: "Quotes",
		},
	},



	// Manage quotes page -- non dashboard version of quoting.
	{
		path: '/module/quotes/manage',
		name: 'quotes-manage',
		component: () => import('./module.quotes/quotes-manage.vue'),
		meta: {
			layout: "primary",
			section: "quotes",
			pageTitle: "Quotes > Manage",
		},
	},



	// New quote Select client / client info page
	{
		path: '/module/quotes/new/select-client',
		name: 'quotes-select-client',
		component: () => import('./module.quotes/quotes-select-client.vue'),
		meta: {
			layout: "primary",
			section: "quotes",
			pageTitle: "Quotes > New > Select Client",
		},
	},


	// New Quote -> new client / client info page
	{
		path: '/module/quotes/new/new-client',
		name: 'quotes-new-client',
		component: () => import('./module.quotes/quotes-new-client.vue'),
		meta: {
			layout: "primary",
			section: "quotes",
			pageTitle: "Quotes > New > New Client",
		},
	},


	{
		path: '/module/quotes/new/confirm-client-details/:id',
		name: 'quotes-confirm-client-details',
		component: () => import('./module.quotes/quotes-confirm-client-details.vue'),
		meta: {
			layout: "primary",
			section: "quotes",
			pageTitle: "Confirm Client details for New Quote",
		},
	},


	// Existing quote / edit quote / review quote page.
	{
		path: '/module/quotes/id/:id/edit',
		name: 'quotes-edit',
		component: () => import('./module.quotes/quotes-edit-quote.vue'),
		meta: {
			layout: "primary",
			section: "quotes",
			pageTitle: "Quotes > Edit Quote",
		},
	},



	// Existing quote / quote overview page .
	{
		path: '/module/quotes/id/:id/overview',
		name: 'quotes-overview',
		component: () => import('./module.quotes/quotes-quote-overview.vue'),
		meta: {
			layout: "primary",
			section: "quotes",
			pageTitle: "Quotes > Quote Overview",
		},
	},






	{ 
		path: '/manage/quotes', 
		name: "manage-quotes", 
		component: () => import("./manage/quotes.vue"),
		meta: { layout: "primary", layoutType: "manage", layoutContainer: "none", pageTitle: "Manage > Quotes", kbFile: "manage", kbTitle: "Manage" }
	},
	{ 
		path: '/manage/quote/:id/edit', 
		name: "manage-quote-edit", 
		component: () => import("./module.quotes/quotes-edit-quote.vue"),
		meta: { layout: "primary", layoutType: "manage", layoutContainer: "none", pageTitle: "Manage > Quote > Edit", kbFile: "manage", kbTitle: "Manage" }
	},

	{
		path: '/manage/quote/:id/overview', 
		name: "manage-quote-overview", 
		component: () => import("./module.quotes/quotes-quote-overview.vue"),
		meta: { layout: "primary", layoutType: "manage", pageTitle: "Manage > Quote > Overview", kbFile: "manage", kbTitle: "Manage" }
	},
























	//---- "Lotus Admin" pages
	{ 
		path: '/system-admin/access-definitions', 
		component: () => import("./system-admin/access-definitions.vue"),
		meta: { layout: "primary", section: "system-admin", pageTitle: "Lotus Admin > Feature Access Definitions" }
	},

	{ 
		path: '/system-admin/timesheet-generation', 
		component: () => import("./system-admin/timesheet-generation.vue"),
		meta: { layout: "primary", section: "system-admin", pageTitle: "Lotus Admin > Timesheet Generation" }
	},

	{ 
		path: '/system-admin/accounts', 
		name: "system-admin-accounts",
		component: () => import("./system-admin/accounts.vue"),
		meta: { layout: "primary", section: "system-admin", pageTitle: "Lotus Admin > Accounts" }
	},
	{ 
		path: '/system-admin/accounts/new', 
		name: "ystem-admin-accounts-edit",
		component: () => import("./system-admin/account.vue"),
		meta: { layout: "primary", section: "system-admin", pageTitle: "Lotus Admin > Account" }
	},

	// { 
	// 	path: '/system-admin/account/:id/edit',
	// 	name: "account-admin-home", 
	// 	component: () => import("./account-admin/index.vue"),
	// 	//meta: { layout: "account-admin", pageTitle: "Account Admin", kbFile: "account-admin", kbTitle: "Account Admin" }
	// 	meta: { layout: "primary", layoutType: "account-admin", layoutContainer: "none", pageTitle: "Account Admin > Account details", kbFile: "account-admin", kbTitle: "Account Admin" }
	// },
	{ 
		path: '/system-admin/account/:id/edit', 
		name: "system-admin-accounts-edit",
		component: () => import("./system-admin/account.vue"),
		meta: { layout: "primary", section: "system-admin", pageTitle: "Lotus Admin > Account > Edit" }
	},


	//TODO: Obsolete

	{ 
		path: '/system-admin/account/:id/rates', 
		name: "system-admin-rates",
		component: () => import("./system-admin/account/rates.vue"),
		meta: { layout: "primary", section: "system-admin", pageTitle: "Lotus Admin > Account > Rates" }
	},
	{ 
		path: '/system-admin/account/:id/clients',
		component: () => import("./system-admin/account/clients.vue"),
		meta: { layout: "primary", section: "system-admin", pageTitle: "Lotus Admin > Account > Clients" }
	},
	{ 
		path: '/system-admin/account/:id/buckets', 
		component: () => import("./system-admin/account/buckets.vue"),
		meta: { layout: "primary", section: "system-admin", pageTitle: "Lotus Admin > Account > Buckets" }
	},
	{ 
		path: '/system-admin/account/:id/buckets/new', 
		component: () => import("./system-admin/account/bucket.vue"),
		meta: { layout: "primary", section: "system-admin", pageTitle: "Lotus Admin > Account > New Bucket" }
	},
	{ 
		path: '/system-admin/account/:id/buckets/:bucketId/edit', 
		component: () => import("./system-admin/account/bucket.vue"),
		meta: { layout: "primary", section: "system-admin", pageTitle: "Lotus Admin > Account > Edit Bucket" }
	},
	{ 
		path: '/system-admin/account/:id/buckets/:bucketId/tasks', 
		component: () => import("./system-admin/account/bucket-tasks.vue"),
		meta: { layout: "primary", section: "system-admin", pageTitle: "Lotus Admin > Account > Bucket Tasks" }
	},
	{ 
		name: "SystemAdminAccountOrganisationRoles",
		path: '/system-admin/account/:id/organisation-roles', 
		component: () => import("./system-admin/account/organisation-roles.vue"),
		meta: { layout: "primary", section: "system-admin", pageTitle: "Lotus Admin > Account > Organisation Roles" }
	},
	{ 
		name: "SystemAdminBucketRates",
		path: '/system-admin/account/:id/buckets/:bucketId/rates', 
		component: () => import("./system-admin/account/rates.vue"),
		meta: { layout: "primary", section: "system-admin", pageTitle: "Lotus Admin > Account > Bucket Rates" }
	},
	{ 
		path: '/system-admin/account/:id/bucket-hierarchies', 
		component: () => import("./system-admin/account/bucket-hierarchies.vue"),
		meta: { layout: "primary", section: "system-admin", pageTitle: "Lotus Admin > Account > Bucket Hierarchies" }
	},
	{ 
		path: '/system-admin/account/:id/keywords', 
		component: () => import("./system-admin/account/keywords.vue"),
		meta: { layout: "primary", section: "system-admin", pageTitle: "Lotus Admin > Keywords" }
	},
	{ 
		path: '/system-admin/account/:id/users', 
		component: () => import("./system-admin/account/users.vue"),
		meta: { layout: "primary", section: "system-admin", pageTitle: "Lotus Admin > Users" }
	},


	{ 
		path: '/system-admin/review-timesheets', 	//NOTE: This is the primary version for the following 4, which should really be considered obsolete and consolidated into this one (via filters)
		component: () => import("./system-admin/timesheets/list-timesheets.vue"),
		name: "SystemAdminReviewTimesheets",
		meta: { layout: "primary", pageTitle: "System Admin > Review Timesheets" }
	},
	{ 
		path: '/system-admin/timesheets/list', 
		component: () => import("./system-admin/timesheets/list-timesheets.vue"),
		name: "SystemAdminTimesheetsList", 
		meta: { layout: "primary", pageTitle: "System Admin > Timesheets" }
	},
	{ 
		path: '/system-admin/timesheets/building', 
		component: () => import("./system-admin/timesheets/list-timesheets.vue"),
		name: "SystemAdminTimesheetsBuilding", 
		meta: { layout: "primary", pageTitle: "System Admin > 'Building Timesheets" }
	},
	{ 
		path: '/system-admin/timesheets/drafts', 
		component: () => import("./system-admin/timesheets/list-timesheets.vue"),
		name: "SystemAdminTimesheetsDrafts", 
		meta: { layout: "primary", pageTitle: "System Admin > 'Draft' Timesheets" }
	},
	{ 
		path: '/system-admin/timesheets/submitted', 
		component: () => import("./system-admin/timesheets/list-timesheets.vue"),
		name: "SystemAdminTimesheetsSubmitted", 
		meta: { layout: "primary", pageTitle: "System Admin > 'Submitted' Timesheets" }
	},
	{ 
		path: '/system-admin/timesheets/id/:id/activity', 
		component: () => import("./system-admin/timesheets/timesheet-activity.vue"),
		name: "SystemAdminTimesheetsTimesheetActivity", 
		meta: { layout: "primary", pageTitle: "System Admin > Timesheet Activity" }
	},



	{ 
		path: '/system-admin/reports', 
		name: "system-admin-reports",
		component: () => import("./system-admin/reports/index.vue"),
		meta: { layout: "primary", pageTitle: "System Admin > Reports" }
	},
	{ 
		path: '/system-admin/reports/database/:id', 
		component: () => import("./system-admin/reports/database.vue"),
		meta: { layout: "primary", pageTitle: "System Admin > Reports > Database Reports" }
	},
	{ 
		path: '/system-admin/reports/:id', 
		name: "system-admin-reports-by-id",
		component: () => import("./system-admin/reports/report.vue"),
		meta: { layout: "primary", pageTitle: "System Admin > Reports" }
	},





















	//---- "Developers" (which includes "Developer Guide", but that's a separate section)
	{ 
		path: '/developers/home', 	//NOTE: 18 July - not currently in the primary nav just because it's not needed. Add it when needed.
		name: "developers-home", 
		component: () => import("./developers/index.vue"),
		meta: { layout: "primary", pageTitle: "Developers > Home" }
	},



	//NOTE: 18 July - not currently in the primary nav just because it's not needed. Add it when needed.
	{ 
		path: '/developers/prototypes/mobile-app', 	
		name: "developers-prototypes-mobile-app", 
		component: () => import("./developers/prototypes/mobile-app.vue"),
		meta: { layout: "no", pageTitle: "Developers > Prototypes > Mobile App" }
	},
	{ 
		path: '/developers/prototypes/desktop-app',
		name: "developers-prototypes-desktop-app", 
		component: () => import("./developers/prototypes/mobile-app.vue"),
		meta: { layout: "no", pageTitle: "Developers > Prototypes > Desktop App" }
	},
	{ 
		path: '/developers/prototypes/desktop-app-notifications',
		name: "developers-prototypes-desktop-app-notifications", 
		component: () => import("./developers/prototypes/mobile-app.vue"),
		meta: { layout: "no", pageTitle: "Developers > Prototypes > Desktop App > Notifications" }
	},





	















	//---- "Developer Guide" (previously "Style Guide")
	{ 
		path: '/developers/dev-guide', 
		name: "dev-guide-home", 
		component: () => import("./developers/dev-guide/index.vue"),
		meta: { layout: "primary", pageTitle: "Developer Guide" }
	},

	{ 
		path: '/developers/dev-guide/layout', 
		name: "dev-guide-layout", 
		component: () => import("./developers/dev-guide/layout.vue"),
		meta: { layout: "primary", pageTitle: "Developer Guide > Layout" }
	},

	{ 
		path: '/developers/dev-guide/typography', 
		name: "dev-guide-typography", 
		component: () => import("./developers/dev-guide/typography.vue"),
		meta: { layout: "primary", pageTitle: "Developer Guide > Typography" }
	},
	{ 
		path: '/developers/dev-guide/buttons', 
		name: "dev-guide-buttons", 
		component: () => import("./developers/dev-guide/buttons.vue"),
		meta: { layout: "primary", pageTitle: "Developer Guide > Buttons" }
	},
	{ 
		path: '/developers/dev-guide/forms', 
		name: "dev-guide-forms", 
		component: () => import("./developers/dev-guide/forms.vue"),
		meta: { layout: "primary", pageTitle: "Developer Guide > Forms" }
	},
	{ 
		path: '/developers/dev-guide/graphics', 
		name: "dev-guide-graphics", 
		component: () => import("./developers/dev-guide/graphics.vue"),
		meta: { layout: "primary", pageTitle: "Developer Guide > Graphics & Iconograohy" }
	},
	{ 
		path: '/developers/dev-guide/tables', 
		name: "dev-guide-tables", 
		component: () => import("./developers/dev-guide/tables.vue"),
		meta: { layout: "primary", pageTitle: "Developer Guide > Tables" }
	},
	{ 
		path: '/developers/dev-guide/other', 
		name: "dev-guide-other", 
		component: () => import("./developers/dev-guide/other.vue"),
		meta: { layout: "primary", pageTitle: "Developer Guide > Other" }
	},
	{ 
		path: '/developers/dev-guide/element-ui', 
		name: "dev-guide-element-ui", 
		component: () => import("./developers/dev-guide/element-ui.vue"),
		meta: { layout: "primary", pageTitle: "Developer Guide > Element UI" }
	},


	{ 
		path: '/developers/dev-guide/test-calendar', 
		name: "dev-guide-test-calendar", 
		component: () => import("./developers/dev-guide/test-calendar.vue"),
		meta: { layout: "primary", pageTitle: "Developer Guide > Test Calendar" }
	},
	{ 
		path: '/developers/dev-guide/test-calendar/:timesheetId', 
		name: "dev-guide-test-calendar-with-timesheet", 
		component: () => import("./developers/dev-guide/test-calendar.vue"),
		meta: { layout: "primary", pageTitle: "Developer Guide > Test Calendar" }
	},
	{ 
		path: '/developers/dev-guide/test-keyword-extraction', 
		name: "dev-guide-test-keyword-extraction", 
		component: () => import("./developers/dev-guide/test-keyword-extraction.vue"),
		meta: { layout: "primary", pageTitle: "Developer Guide > Test Keyword Extraction" }
	},


	{ 
		path: '/developers/dev-guide/buttons-that-are-obsolete', 
		name: "dev-guide-buttons-that-are-obsolete", 
		component: () => import("./developers/dev-guide/buttons-that-are-obsolete.vue"),
		meta: { layout: "primary", pageTitle: "Developer Guide > Obsolete: Buttons" }
	},




















	//---- "KB"
	{ 
		path: '/kb', 
		name: "kb", 
		component: () => import("./kb/index.vue"),
		meta: { layout: "primary", pageTitle: "Knowledgebase" } 
	},
	{ 
		path: '/kb/with-path',
		name: "kb-with-path", 
		component: () => import("./kb/index.vue"),
		meta: { layout: "knowledgebase", pageTitle: "Knowledgebase" } 
	},
	{ 
		path: '/kb/route/:routeName', 
		name: "kb-with-route-name", 
		component: () => import("./kb/index.vue"),
		meta: { layout: "primary", pageTitle: "Knowledgebase" } 
	},
	{ 
		path: '/kb/glossary/:term', 
		name: "kb-glossary", 
		component: () => import("./kb/index.vue"),
		meta: { layout: "primary", pageTitle: "Knowledgebase > Glossary" } 
	},














	//---- "Background Tasks"
	{ 
		path: '/background-tasks',
		name: "background-tasks", 
		component: () => import("./home/background-tasks.vue"),
		meta: { layout: "primary", pageTitle: "Background Tasks" }
	},





















	
	//---- Debugger
	{ 
		path: '/debugger/mobile/location-with-comments', 
		name: "DebuggerMobileLocationWithComments",
		component: () => import("./debugger/mobile-location-with-comments.vue"),
		meta: { layout: "primary", pageTitle: "Debugger: Mobile > Location With Comments", kbFile: "", kbTitle: "" } 
	},

	{ 
		path: '/debugger/debug-logs', 
		name: "debugger-debug-logs",
		component: () => import("./debugger/debug-logs.vue"),
		meta: { layout: "primary", pageTitle: "Debugger: Debug Logs", kbFile: "", kbTitle: "" } 
	},



















	//---- Test
	{ 
		path: '/test', 
		name: "test-index", 
		component: () => import("./test/index.vue"),
		meta: { layout: "no", layoutType: "", isPublic: true, pageTitle: "Test E" } 
		//meta: { layout: "public", layoutType: "default", isPublic: true, pageTitle: "Test B" } 
		//meta: { layout: "primary", layoutType: "default", layoutContainer: "none", isPublic: true, pageTitle: "Test C", kbFile: "home", kbTitle: "Test C" } 
	},
	{ 
		path: '/test/input', 
		name: "testC-input", 
		component: () => import("./test/input.vue"),
		meta: { layout: "no", layoutType: "", isPublic: true, pageTitle: "Test Input" } 
		//meta: { layout: "public", layoutType: "default", isPublic: true, pageTitle: "Test B" } 
		//meta: { layout: "primary", layoutType: "default", layoutContainer: "none", isPublic: true, pageTitle: "Test C", kbFile: "home", kbTitle: "Test C" } 
	},
	{ 
		path: '/test/simple-form', 
		name: "test-simple-form", 
		component: () => import("./test/simple-form.vue"),
		meta: { layout: "primary", layoutType: "", isPublic: true, pageTitle: "Test Simple Form Renderer" } 
	},
	{ 
		path: '/test/simple-table', 
		name: "test-simple-table", 
		component: () => import("./test/simple-table.vue"),
		meta: { layout: "primary", layoutType: "", isPublic: true, pageTitle: "Test Simple Table Renderer" } 
	},



	{ 
		path: '/test/chatgpt/table2', 
		name: "test-chatgpt-table2", 
		component: () => import("./test/chatgpt/table2-host.vue"),
		meta: { layout: "primary", layoutType: "", isPublic: true, pageTitle: "Test > ChatGPT > Table 2" } 
	},

















	//---- Go. Allow list redirector for the mobile app and other applications.
	{ 
		path: '/go/:id', 
		name: "Go",
		component: () => import("./go/index.vue"),
		meta: { layout: "no", isPublic: true, pageTitle: "", kbFile: "", kbTitle: "" } 
	},





















	//---- "Mobile App" web view pages 
	//     (for direct access from the mobile app, either in a web view or in a native browser)
	//NOTE: These are from the original version of the Lotus app, for the quoting module.
	{ 
		path: '/mobile-app/manage-jobs',
		name: "mobile-app-manage-jobs", 
		component: () => import("./mobile-app/manage-jobs.vue"),
		meta: { layout: "mobileappembeddedview", layoutType: "default", layoutContainer: "card", pageTitle: "Manage Jobs" }
	},
	{ 
		path: '/mobile-app/new-job',
		name: "mobile-app-new-job", 
		component: () => import("./mobile-app/edit-job.vue"),
		meta: { layout: "mobileappembeddedview", layoutType: "default", layoutContainer: "card", pageTitle: "New Job" }
	},
	{ 
		path: '/mobile-app/edit-job/:jobId',
		name: "mobile-app-edit-job", 
		component: () => import("./mobile-app/edit-job.vue"),
		meta: { layout: "mobileappembeddedview", layoutType: "default", layoutContainer: "card", pageTitle: "Modify Job" }
	},



	//---- "Mobile App" V2 - a web native "app" for running in the browser.
	{ 
		path: '/app/home',
		name: "mobile-app-home", 
		component: () => import("./mobile-app/home.vue"),
		meta: { layout: "mobileapp", layoutType: "default", layoutContainer: "card", pageTitle: "Home" }
	},













	//---- CRM
	{
		path: '/crm',
		name: "crm-index", 
		component: () => import("./crm/index.vue"),
		meta: { layout: "primary", layoutContainer: "none", pageTitle: "CRM" }
	},

	{
		path: '/crm/configure',
		name: "crm-configure", 
		component: () => import("./crm/configure.vue"),
		meta: { layout: "primary", layoutContainer: "none", pageTitle: "CRM > Configure" }
	},











	//---- "Road Management" (POC)
	{
		path: '/module/pavements-poc',
		name: "pavements-index", 
		component: () => import("./module.pavements-poc/index.vue"),
		meta: { layout: "primary", layoutContainer: "none", pageTitle: "Pavements (PoC)" }
	},

	{
		path: '/module/pavements-poc/import',
		name: "pavements-import", 
		component: () => import("./module.pavements-poc/import.vue"),
		meta: { layout: "primary", layoutContainer: "none", pageTitle: "Pavements (PoC) > Import" }
	},

	{
		path: '/module/pavements-poc/defects',
		name: "pavements-defects", 
		component: () => import("./module.pavements-poc/defects.vue"),
		meta: { layout: "primary", layoutContainer: "none", pageTitle: "Pavements (PoC) > Defects" }
	},

	{
		path: '/module/pavements-poc/defect/new',
		name: "pavements-defect-new", 
		component: () => import("./module.pavements-poc/defects.vue"),
		meta: { layout: "primary", layoutContainer: "none", pageTitle: "Pavements (PoC) > New Defect " }
	},

	{
		path: '/module/pavements-poc/defect/edit/:internalId',
		name: "pavements-defect-edit", 
		component: () => import("./module.pavements-poc/defects.vue"),
		meta: { layout: "primary", layoutContainer: "none", pageTitle: "Pavements (PoC) > Defect " }
	},

	// road management maps test
	{
		path: '/module/pavements-poc/map',
		name: "pavements-map", 
		component: () => import("./module.pavements-poc/map-display.vue"),
		meta: { layout: "primary", layoutContainer: "none", pageTitle: "Pavements (PoC)" }
	},
























	//---- Module: "Tessta" (admin portal)
	{ 
		path: '/module/tessta/example-endpoint', 
		name: "tessta-admin-example", 
		component: () => import("./module.tessta/example-endpoint.vue"),
		meta: { layout: "primary", module: "tessta", pageTitle: "Tessta Example" }
	},




	{ 
		path: '/module/tessta', 
		name: "tessta-admin-root", 
		component: () => import("./module.tessta/home/index.vue"),
		meta: { layout: "primary", module: "tessta", pageTitle: "Tessta" }
	},

	{ 
		path: '/module/tessta/home', 
		name: "tessta-admin-home-index", 
		component: () => import("./module.tessta/home/index.vue"),
		meta: { layout: "primary", module: "tessta", pageTitle: "Tessta > Home" }
	},


	{ 
		path: '/module/tessta/clients', 
		name: "tessta-admin-clients-index", 
		component: () => import("./module.tessta/clients/index.vue"),
		meta: { layout: "primary", module: "tessta", pageTitle: "Tessta > Clients" }
	},
	{ 
		path: '/module/tessta/clients/create', 
		name: "tessta-admin-clients-create", 
		//component: () => import("./module.tessta/clients/create-client.vue"),
		component: () => import("./module.tessta/clients/edit-client.vue"),
		meta: { layout: "primary", module: "tessta", pageTitle: "Tessta > Clients > Create" }
	},
	{ 
		path: '/module/tessta/clients/edit/:id', 
		name: "tessta-admin-clients-edit", 
		component: () => import("./module.tessta/clients/edit-client.vue"),
		props:true,
		meta: { layout: "primary", module: "tessta", pageTitle: "Tessta > Clients > Edit" }
	},


	{ 
		path: '/module/tessta/sites', 
		name: "tessta-admin-sites-index", 
		component: () => import("./module.tessta/sites/index.vue"),
		meta: { layout: "primary", module: "tessta", pageTitle: "Tessta > Sites" }
	},
	// { 
	// 	path: '/module/tessta/sites/create', 
	// 	name: "tessta-admin-sites-create", 
	// 	component: () => import("./module.tessta/sites/create-site.vue"),
	// 	meta: { layout: "primary", module: "tessta", pageTitle: "Tessta > Sites > Create" }
	// },
	{ 
		path: '/module/tessta/sites/create', 
		name: "tessta-admin-sites-create", 
		props:true, 
		component: () => import("./module.tessta/sites/edit-site.vue"),
		meta: { layout: "primary", module: "tessta", pageTitle: "Tessta > Sites > Create" }
	},

	{ 
		path: '/module/tessta/sites/edit/:siteId', 
		name: "tessta-admin-sites-edit",
		props:true, 
		component: () => import("./module.tessta/sites/edit-site.vue"),
		meta: { layout: "primary", module: "tessta", pageTitle: "Tessta > Sites > Edit" }
	},

	{ 
		path: '/module/tessta/assessment/:assessmentId', 
		name: "tessta-admin-sites-assessment",
		props:true, 
		component: () => import("./module.tessta/sites/assessment-site.vue"),
		meta: { layout: "primary", module: "tessta", pageTitle: "Tessta > Sites > Assessment" }
	},


	{ 
		path: '/module/tessta/projects', 
		name: "tessta-admin-projects-index", 
		component: () => import("./module.tessta/projects/index.vue"),
		meta: { layout: "primary", module: "tessta", pageTitle: "Tessta > Projects" }
	},
	{ 
		path: '/module/tessta/projects/create', 
		name: "tessta-admin-projects-create", 
		component: () => import("./module.tessta/projects/edit-project.vue"),	//TODO: Change this to a correct create page
		meta: { layout: "primary", module: "tessta", pageTitle: "Tessta > Projects > Create" }
	},
	{ 
		path: '/module/tessta/projects/edit/:projectId', 
		name: "tessta-admin-projects-edit", 
		component: () => import("./module.tessta/projects/edit-project.vue"),
		meta: { layout: "primary", module: "tessta", pageTitle: "Tessta > Projects > Project" }
	},


	{ 
		path: '/module/tessta/projects/my-app-data', 
		name: "tessta-admin-user-app-data", 
		component: () => import("./module.tessta/projects/user-app-data/user-app-data.vue"),
		meta: { layout: "mobileapp", module: "tessta", pageTitle: "Tessta > My current live app data" }
	},


	//


	{ 
		path: '/module/tessta/qa', 
		name: "tessta-admin-qa-index", 
		component: () => import("./module.tessta/qa/index.vue"),
		meta: { layout: "primary", module: "tessta", pageTitle: "Tessta > QA" }
	},


	{ 
		path: '/module/tessta/defects', 
		name: "tessta-admin-defects-index", 
		component: () => import("./module.tessta-shared/defects/index.vue"),
		meta: { layout: "primary2", module: "tessta", pageTitle: "Tessta > Defects" },
		props:true, 
	},
	{ 
		path: '/module/tessta/defects/view/:defectId', 
		name: "tessta-admin-defects-edit",
		props:true,
		component: () => import("./module.tessta-shared/defects/view-defect.vue"),
		meta: { layout: "primary2", module: "tessta", pageTitle: "Tessta > Defects > View Defect" }
	},


	{ 
		path: '/module/tessta/configuration', 
		name: "tessta-admin-configuration-index", 
		component: () => import("./module.tessta/configuration/index.vue"),
		meta: { layout: "primary", module: "tessta", pageTitle: "Tessta > Configuration" }
	},

	{ 
		path: '/module/tessta/configuration/users', 
		name: "tessta-admin-configuration-users-index", 
		component: () => import("./module.tessta/configuration/users.vue"),
		meta: { layout: "primary", module: "tessta", pageTitle: "Tessta > Configuration > Users" }
	},
	{ 
		path: '/module/tessta/configuration/users/edit/:userId', 
		name: "tessta-admin-configuration-user-edit", 
		component: () => import("./module.tessta/configuration/edit-user.vue"),
		meta: { layout: "primary", module: "tessta", pageTitle: "Tessta > Configuration > Users > User" }
	},

	{ 
		path: '/module/tessta/configuration/assessments', 
		name: "tessta-admin-configuration-assessments-index", 
		component: () => import("./module.tessta/configuration/assessments.vue"),
		meta: { layout: "primary", module: "tessta", pageTitle: "Tessta > Configuration > Assessments" }
	},
	{ 
		path: '/module/tessta/configuration/assessments/create', 
		name: "tessta-admin-configuration-assessment-create", 
		component: () => import("./module.tessta/configuration/edit-assessment.vue"),
		meta: { layout: "primary", module: "tessta", pageTitle: "Tessta > Configuration > Assessments > Create Assessment" }
	},
	{ 
		path: '/module/tessta/configuration/assessments/edit/:assessmentId', 
		name: "tessta-admin-configuration-assessment-edit", 
		component: () => import("./module.tessta/configuration/edit-assessment.vue"),
		meta: { layout: "primary", module: "tessta", pageTitle: "Tessta > Configuration > Assessments > Edit Assessment" }
	},

	{ 
		path: '/module/tessta/configuration/assets', 
		name: "tessta-admin-configuration-assets-index", 
		component: () => import("./module.tessta/configuration/assets.vue"),
		meta: { layout: "primary", module: "tessta", pageTitle: "Tessta > Configuration > Assets" }
	},
	{ 
		path: '/module/tessta/configuration/assets/create', 
		name: "tessta-admin-configuration-asset-create", 
		component: () => import("./module.tessta/configuration/edit-asset.vue"),
		meta: { layout: "primary", module: "tessta", pageTitle: "Tessta > Configuration > Assets > Create Asset" }
	},
	{ 
		path: '/module/tessta/configuration/assets/edit/:assetId', 
		name: "tessta-admin-configuration-asset-edit", 
		component: () => import("./module.tessta/configuration/edit-asset.vue"),
		meta: { layout: "primary", module: "tessta", pageTitle: "Tessta > Configuration > Assets > Edit Asset" }
	},

	{ 
		path: '/module/tessta/configuration/reference-data', 
		name: "tessta-admin-configuration-reference-data-index", 
		component: () => import("./module.tessta/reference-data/index.vue"),
		meta: { layout: "primary", module: "tessta", pageTitle: "Tessta > Configuration > Reference Data" }
	},

	//TODO: Obsolete for now. All editing is done on the index page for now.
	// { 
	// 	path: '/module/tessta/configuration/reference-data/edit/:id', 
	// 	name: "tessta-admin-configuration-reference-data-edit", 
	// 	component: () => import("./module.tessta/reference-data/edit-referene-data.vue"),
	// 	meta: { layout: "primary", module: "tessta", pageTitle: "Tessta > Configuration > Reference Data > Edit" }
	// },


	//TODO: The following now all come under "Assets" I think.

	{ 
		path: '/module/tessta/configuration/work-orders', 
		name: "tessta-admin-configuration-work-orders-index", 
		component: () => import("./module.tessta/configuration/work-orders.vue"),
		meta: { layout: "primary", module: "tessta", pageTitle: "Tessta > Configuration > Work Orders" }
	},
	{ 
		path: '/module/tessta/configuration/work-orders/edit/:workOrderId', 
		name: "tessta-admin-configuration-work-orders-edit", 
		component: () => import("./module.tessta/configuration/edit-work-order.vue"),
		meta: { layout: "primary", module: "tessta", pageTitle: "Tessta > Configuration > Work Orders > Work Order" }
	},

	{ 
		path: '/module/tessta/configuration/room-elements', 
		name: "tessta-admin-configuration-room-elements-index", 
		component: () => import("./module.tessta/configuration/room-elements.vue"),
		meta: { layout: "primary", module: "tessta", pageTitle: "Tessta > Configuration > Room Elements" }
	},
	{ 
		path: '/module/tessta/configuration/room-elements/edit/:roomElementId', 
		name: "tessta-admin-configuration-room-elements-edit", 
		component: () => import("./module.tessta/configuration/edit-room-element.vue"),
		meta: { layout: "primary", module: "tessta", pageTitle: "Tessta > Configuration > Room Elements > Room Element" }
	},








	//---- Module: "Tessta Clients" (Tessta Client Portal)
	{ 
		path: '/module/tessta-clients', 
		name: "tessta-clients-index", 
		component: () => import("./module.tessta-clients/home/index.vue"),
		meta: { layout: "primary2", module: "tesstaClients", pageTitle: "Tessta" }
	},

	{ 
		path: '/module/tessta-clients/home', 
		name: "tessta-clients-home-index", 
		component: () => import("./module.tessta-clients/home/index.vue"),
		meta: { layout: "primary2", module: "tesstaClients", pageTitle: "Tessta > Home" },
		props:true, 
	},


	{ 
		path: '/module/tessta-clients/sites', 
		name: "tessta-clients-sites-index", 
		props:true, 
		component: () => import("./module.tessta-clients/sites/index.vue"),
		meta: { layout: "primary2", module: "tesstaClients", pageTitle: "Tessta > Sites" }
	},
	{ 
		path: '/module/tessta-clients/sites/view/:siteId', 
		name: "tessta-clients-sites-edit",
		props:true, 
		component: () => import("./module.tessta-clients/sites/view-site.vue"),
		meta: { layout: "primary2", module: "tesstaClients", pageTitle: "Tessta > Sites > View Site" }
	},


	{ 
		path: '/module/tessta-clients/defects', 
		name: "tessta-clients-defects-index", 
		component: () => import("./module.tessta-shared/defects/index.vue"),
		meta: { layout: "primary2", module: "tesstaClients", pageTitle: "Tessta > Defects" },
		props:true, 
	},
	{ 
		path: '/module/tessta-clients/defects/view/:defectId', 
		name: "tessta-clients-defects-edit",
		props:true,
		component: () => import("./module.tessta-shared/defects/view-defect.vue"),
		meta: { layout: "primary2", module: "tesstaClients", pageTitle: "Tessta > Defects > View Defect" }
	},



	// { 
	// 	path: '/module/tessta-clients/assessments', 
	// 	name: "tessta-clients-assessments-index", 
	// 	component: () => import("./module.tessta-shared/defects/index.vue"),
	// 	meta: { layout: "primary2", module: "tesstaClients", pageTitle: "Tessta > Defects" }
	// },




















	//---- Module: "Rostering" -----//
	{ 
		path: '/module/rostering', 
		name: "rostering-index", 
		component: () => import("./module.rostering/index.vue"),
		meta: { layout: "primary", module: "rostering", pageTitle: "Rostering" }
	},




	//-- Rostering > Kiosk (Roster Check-in)
	// { 
	// 	path: '/module/rostering/kiosk-v1', 
	// 	name: "rostering-kiosk-index", 
	// 	component: () => import("./module.rostering/kiosk/index.vue"),
	// 	meta: { layout: "rostered", module: "rostering", pageTitle: "" }
	// },
	{ 
		path: '/module/rostering/kiosk', 
		name: "rostering-kiosk-index", 
		component: () => import("./module.rostering/kiosk/index.vue"),
		meta: { layout: "none", module: "rostering", pageTitle: "" }
	},

	// The old path. This needs a redirect to '/module/rostering/kiosk'
	{ 
		path: '/module/rostering/dashboard', 
		name: "rostering-dashboard-index", 
		component: () => import("./module.rostering/kiosk/index.vue"),
		meta: { layout: "none", module: "rostering", pageTitle: "" }
	},




	//-- Rostering > Manage
	{ 
		path: '/module/rostering/admin', 
		name: "rostering-admin-page", 
		component: () => import("./module.rostering/manage/rostered-jobs.vue"),
		meta: { layout: "primary", layoutContainer: "none", module: "rostering", pageTitle: "Rostering Admin" }
	},


	{ 
		path: '/module/rostering/admin/new', 
		name: "rostering-admin-new-job", 
		component: () => import("./module.rostering/manage/edit-roster-job.vue"),
		meta: { layout: "primary", layoutContainer: "none", module: "rostering", pageTitle: "Rostering Admin > New Job" }
	},
	

	{ 
		path: '/module/rostering/admin/job/:jobId/edit', 
		name: "rostering-admin-edit-job", 
		component: () => import("./module.rostering/manage/edit-roster-job.vue"),
		meta: { layout: "primary", layoutContainer: "none", module: "rostering", pageTitle: "Rostering Admin > Edit Job" }
	},

	

	{ 
		path: '/module/rostering/admin/reports/payroll', 
		name: "rostering-admin-reports-payroll", 
		component: () => import("./module.rostering/manage/payroll-report.vue"),
		meta: { layout: "primary", layoutContainer: "none", module: "rostering", pageTitle: "Rostering Admin > Payroll Report" }
	},


]


export default routes;