<!-- eslint-disable vue/multi-word-component-names -->
<template>
	<!-- "!isDeep": Just the column on it's own -->
	<div :id="id" :class="getClass" :style="getStyle" v-if="!isDeep">
		<slot></slot>
	</div>


	<!-- "!isDeep": Auto-generate the parent row and grid layout container -->
	<c-grid v-if="isDeep">
		<c-row>
			<div :id="id" :class="getClass" :style="getStyle">
				<slot></slot>
			</div>
		</c-row>
	</c-grid>
</template>





<script>
export default {

	props: {
		
		/**
		 * The field's `id` value. Required.  
		 * Should be unique among all elements currently rendered on the page.
		 */
		id: {
			type: String,
			required: false
		},

		/**
		 * Additional CSS class(s) to apply.  
		 * Does NOT overwrite default classes.
		 */
		cssClass: {
			type: String
		},

		/**
		 * The number of columns in width on "Large" size screens.
		 * Out of 12 possible columns in a row.
		 * Implement as `:cols=""` on elements.
		 */
		cols: {
			type: Number,
			required: false,
			default: 12		// Full width by default
		},


		//TODO: Note - this is not yet implemented. Placeholder for later
		/**
		 * The number of columns in width on "Medium" size screens (e.g. tablets).
		 * Out of 12 possible columns in a row.
		 * Implement as `:cols-s=""` on elements.
		 */
		colsM: {
			type: Number,
			required: false,
			default: 12		// Full width by default
		},


		//TODO: Note - this is not yet implemented. Placeholder for later
		/**
		 * The number of columns in width on "Small" size screens (e.g. phones).
		 * Out of 12 possible columns in a row.
		 * Implement as `:cols-s=""` on elements.
		 */
		colsS: {
			type: Number,
			required: false,
			default: 12		// Full width by default
		},


		/**
		 * Set to true to auto-generate the grid/layout and row this column lives in.
		 * Saves having to do all the wrapper elements in the host page/component.
		 */
		isDeep: {
			type: Boolean,
			required: false,
			default: false
		}

	},





	data()
	{
		return {

		};
	},



	// watch: {
	// 	currentPageNumber: function (to, from) {
	//
	// 	}
	// },






	mounted()
	{
		//console.log("this.$attrs=", this.$attrs);
	},





	computed: {
		getClass()
		{
			const fallthrough = this.$attrs.class;

			//console.log("[" + this.id + "] fallthrough=", fallthrough);

			let full = "col-" + this.cols;

			// // Version 1: If the window with is below a threshold drop everything down to full-width (12 columns).
			// // This takes into account an always open size menu.
			// // Note: The window needs a refresh for this to take affect (unless CSS breakpoints)
			// var windowWidth = window.innerWidth;
			// console.log("windowWidth=", windowWidth);
			// if (windowWidth < 1028)
			// {
			// 	full = "col-12";
			// }

			// Version 1: Once a window is DOWN to the Bootstrap "Large" breakpoint, drop over column down to full-width (12 cols)
			full = full + " control-for-full-width";

			//TODO: The next version..
			/*
				We should define other breakpoint col sizes:
				:cols-l (large) - this is also the existing :cols		(Bootstrap Large, Extra large, Extra extra large)
				:colm-m (medium) - not sure about this.					(Bootstrap Medium)
				:cols-s													(Bootstrap Small, Extra Small)

				I think we wonly want 3 sizes: large screen (laptop and larger, small (phone, etc), and medium (in between - tablet maybe))
				Ref: https://getbootstrap.com/docs/5.0/layout/breakpoints/

				Actually, we need to take into account the size menu

			*/



			if (fallthrough)
			{
				full = full + " " + fallthrough;
			}


			return full;
		},

		getStyle()
		{
			const fallthrough = this.$attrs.style;

			let full = "";	// Nothing set at the moment

			if (fallthrough)
			{
				full = fallthrough;
			}


			return full;
		}
	},





	methods: {

	}
}
</script>





<style scoped lang="scss">
	//Ref: https://getbootstrap.com/docs/5.0/layout/breakpoints/
	
	// Once the screen is down to "1199.98px" (Boostrap Large) then ensure all 
	// columns are full width (equivalent of 12 columns). Hence the need for !important
	// to override the current Bootstrap col-* classes with work in the opposite direction.
	@media (max-width: 1199.98px) {
		.control-for-full-width
		{
			flex: 0 0 100% !important;
			max-width: 100% !important;
		}
	}
</style>